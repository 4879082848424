<template>
    <div class="project-tasks-page">
        <full-loading ref="fullLoading"></full-loading>
        <div class="page-tools-content" style="margin: 0; padding: 0;">
            <div class="left">
                <el-tabs class="filter-tabs task-status-tabs" v-model="activeFilter" @tab-click="filterClickEvent">
                    <el-tab-pane :label="$t('project.task_page_tab_label_1')" name="ALL"></el-tab-pane>
                    <el-tab-pane :label="$t('project.task_page_tab_label_2')" name="ASSIGNED"></el-tab-pane>
                    <el-tab-pane :label="$t('project.task_page_tab_label_3')" name="IN_PROGRESS"></el-tab-pane>
                    <!-- <el-tab-pane label="待提交" name="COMPLETED"></el-tab-pane> -->
                    <el-tab-pane :label="$t('project.task_page_tab_label_4')" name="FINISHED"></el-tab-pane>
                    <el-tab-pane :label="$t('project.task_page_tab_label_5')" name="INVALID"></el-tab-pane>
                </el-tabs>
                
            </div>
            <div class="right" style="padding: 12px 0">
                <div style="width:220px;" class="margin-l-10">
                    <el-input :placeholder="$t('project.task_page_search_kw_placeholder')" 
                        size="small" round suffix-icon="el-icon-search" maxlength="100" clearable
                        v-model="searchForm.kw" @input="inputSearchEvent"></el-input>
                </div>
                <template v-if="view === 'list'">
                    <template v-if="activeFilter === 'ASSIGNED'">
                        <el-tooltip :content="$t('project.task_page_search_button_text_1')" placement="top">
                            <el-button size="small" plain round class="margin-l-10" style="padding: 6.4px; font-size: 18px;" @click="batchAcceptBtnEvent">
                                <svg-icon name="ib-check-circle"></svg-icon>
                            </el-button>
                        </el-tooltip>
                        <el-tooltip :content="$t('project.task_page_search_button_text_2')" placement="top">
                            <el-button size="small" plain round style="padding: 6.4px; font-size: 18px;" @click="batchRefuseBtnEvent">
                                <svg-icon name="ib-error-o"></svg-icon>
                            </el-button>
                        </el-tooltip>
                    </template>
                    <template v-if="activeFilter === 'IN_PROGRESS'">
                        <el-tooltip :content="$t('project.task_page_search_button_text_4')" placement="top">
                            <el-button :loading="batchSubmitBtnLoading" size="small" plain round class="margin-l-10" style="padding: 6.4px; font-size: 18px;" @click="showbatchSubmitDialogEvent">
                                <svg-icon name="ib-submit" v-if="!batchSubmitBtnLoading"></svg-icon>
                            </el-button>
                        </el-tooltip>
                    </template>
                </template>
                <el-tooltip :content="$t('project.task_page_search_button_text_3')" placement="top">
                    <el-button size="small" plain round icon="el-icon-refresh-left" class="margin-l-10" style="padding: 6.4px; font-size: 18px;" :loading="refreshLoading" @click="initPage(false)"></el-button>
                </el-tooltip>
                <div class="margin-l-10" v-if="userInfo.organizationType === 'ENTERPRISE'">
                    <el-popover placement="bottom" trigger="click">
                        <div class="more-filter-content">
                            <el-form label-width="90px" style="width: 290px;">
                                <el-form-item :label="$t('project.task_page_search_taskType_placeholder')" class="margin-b-5">
                                    <el-select v-model="searchForm.taskType" size="small" :placeholder="$t('project.task_page_search_taskType_placeholder')" @change="selectSearchEvent" clearable>
                                            <template v-for="item in taskTypeOptions">
                                                <el-option :key="item.code" :value="item.code" :label="item.desc"></el-option>
                                            </template>
                                        </el-select>
                                </el-form-item>
                                <el-form-item :label="$t('project.task_page_search_source_placeholder')" class="margin-b-5">
                                    <el-select size="small" :placeholder="$t('project.task_page_search_source_placeholder')" filterable clearable v-model="searchForm.source" @change="selectSearchEvent">
                                        <template v-for="(item,key) in LANGUAGE_DICT">
                                            <el-option :key="key" :value="key" :label="item.name" :disabled="searchForm.target === item.key"></el-option>
                                        </template>
                                    </el-select>
                                </el-form-item>
                                <el-form-item :label="$t('project.task_page_search_target_placeholder')" class="margin-b-5">
                                    <el-select size="small" :placeholder="$t('project.task_page_search_target_placeholder')" filterable clearable v-model="searchForm.target" @change="selectSearchEvent">
                                        <template v-for="(item,key) in LANGUAGE_DICT">
                                            <el-option :key="key" :value="key" :label="item.name" :disabled="searchForm.source === item.key"></el-option>
                                        </template>
                                    </el-select>
                                </el-form-item>
                                <el-form-item :label="$t('project.task_page_search_order_placeholder')" class="margin-b-5">
                                    <el-select v-model="searchForm.order" size="small" :placeholder="$t('project.task_page_search_order_placeholder')" @change="selectSearchEvent" clearable>
                                        <template v-for="item in orderOptions">
                                            <el-option :key="item.value" :value="item.value" :label="item.text"></el-option>
                                        </template>
                                    </el-select>
                                </el-form-item>
                            </el-form>
                        </div>
                        <el-badge slot="reference" :value="filterCount" class="more-filter-badge" :hidden="filterCount === 0">
                            <el-button plain round size="small" style="padding: 6.4px; font-size: 18px;">
                                <svg-icon name="ib-funnel"></svg-icon>
                            </el-button>
                        </el-badge>
                    </el-popover>
                </div>
                <div class="margin-l-10">
                    <el-tooltip :content="`${$t('project.task_page_search_switch_button_text_1')}${view==='card' ? $t('project.task_page_search_switch_button_text_2') : $t('project.task_page_search_switch_button_text_3')}${$t('project.task_page_search_switch_button_text_4')}`" placement="top">
                        <el-button plain round size="small" @click="changeViewEvent" style="padding: 6.4px; font-size: 18px;">
                            <svg-icon name="ib-list" v-if="view === 'card'"></svg-icon>
                            <svg-icon name="ib-card" v-if="view === 'list'"></svg-icon>
                        </el-button>
                    </el-tooltip>
                </div>
            </div>
        </div>
        <template v-if="view === 'card'">
            <div class="task-card-list" v-if="list.length">
                <template v-for="(item, index) in list">
                    <div class="item" :key="index" @click="toWorkbench(item)">
                        <div class="task-type-label" :class="{'rejected': item.returned}">{{item.taskTypeDesc}}{{item.returned ? $t('project.task_page_table_colum_tips_2') : ''}}</div>
                        <div class="flex flex-justify-center">
                            <div class="icon-content">
                                <svg-icon :name="`ic-${config.FILE_TYPE_MATCH['.'+(item.taskFileType || 'doc')].icon}`"></svg-icon>
                            </div>
                        </div>
                        <el-popover placement="top-start" trigger="hover">
                            <div class="fonts-12 line-height-1.5">
                                {{$t('project.task_page_card_item_label_1')}}{{item.taskName}}<br>{{$t('project.task_page_card_item_label_2')}}{{item.startTime}}
                            </div>
                            <div slot="reference" class="fonts-15 margin-t-10 text-center text-omission">{{item.taskName}}</div>
                        </el-popover>
                        <div class="flex flex-justify-center fonts-12 margin-t-8">
                            <el-tooltip placement="top">
                                <span slot="content">
                                    {{LANGUAGE_DICT[item.sourceLang].name}}<svg-icon name="ib-arrow-right"></svg-icon>{{LANGUAGE_DICT[item.targetLang].name}}
                                </span>
                                <span>
                                    {{LANGUAGE_DICT[item.sourceLang].shortName}}<svg-icon name="ib-arrow-right"></svg-icon>{{LANGUAGE_DICT[item.targetLang].shortName}}
                                </span>
                            </el-tooltip>
                            <el-divider direction="vertical" class="margin-x-8"></el-divider>
                            <span>{{item.sourceWords}} {{$t('project.task_page_card_item_label_3')}}</span>
                        </div>
                        <div class="flex flex-justify-center margin-t-8 fonts-12">
                            <span :class="item.status">{{item.statusDesc}}</span>
                            <template v-if="item.pkFailureCode">
                                <el-tooltip :content="`${$t('project.task_page_table_colum_tips_3')}${config.TASK_IMPORT_ERROR_OPTS[item.pkFailureCode]}`" placement="top">
                                    <svg-icon name="ib-warning-o" className="margin-l-5 color-error"></svg-icon>
                                </el-tooltip>
                            </template>
                        </div>
                        <div class="flex flex-justify-center margin-t-10 fonts-12 color-808080">{{$t('project.task_page_card_item_label_4')}}<span :class="{'color-error': item.overdue}">{{item.deadlineTime || '—'}}</span></div>
                        <div class="lanx-btn-group margin-t-10" v-if="item.status === 'ASSIGNED'">
                            <el-button type="text" size="small" @click.stop="refuseBtnEvent([item])" v-permission="`tasks:refuse`" style="color: #808080;">{{$t('project.task_page_card_item_label_5')}}</el-button>
                            <el-button type="text" size="small" @click.stop="acceptBtnEvent([item])" v-permission="`tasks:toAccept`">{{$t('project.task_page_card_item_label_6')}}</el-button>
                        </div>
                        <template v-else>
                            <div class="margin-t-10 relative" v-if="item.status !== 'REJECTED'">
                                <el-progress :show-text="false" :stroke-width="32" :percentage="item.progress * 1" color="#D3F4EC" text-color="#1AC49C"></el-progress>
                                <div class="progress-text"><span class="color-1AC49C text-weight-600 margin-l-5">{{ item.progress }}{{$t('project.task_page_card_item_label_7')}}</span></div>
                            </div>
                        </template>
                        <div class="more-actions" v-if="['REJECTED'].indexOf(item.status) === -1">
                            <el-dropdown @command="command => executeAction(command, [item])">
                                <div class="fonts-20 cursor-pointer">
                                    <svg-icon name="ib-more"></svg-icon>
                                </div>
                                <el-dropdown-menu slot="dropdown">
                                    <template v-if="item.status === 'IN_PROGRESS'">
                                        <el-dropdown-item command="import">{{$t('project.task_page_card_item_dropdown_item_1')}}</el-dropdown-item>
                                        <el-dropdown-item command="export">{{$t('project.task_page_card_item_dropdown_item_2')}}</el-dropdown-item>
                                    </template>
                                    <template>
                                        <el-dropdown-item command="showOffer">{{$t('project.task_page_card_item_dropdown_item_3')}}</el-dropdown-item>
                                    </template>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>
                    </div>
                </template>
            </div>
        </template>
        <div class="margin-t-15" v-else-if="view === 'list'">
            <task-table-list ref="taskTableList" :list="list" @action="executeAction"></task-table-list>
        </div>
        <div class="none-list-block" v-else>
            <div class="none-files-content">
                <svg-icon name="ib-empty"></svg-icon>
                <div class="info">{{$t('project.task_page_empty_label_1')}}</div>
            </div>
        </div>
        <div class="pagination-content" v-if="list.length > 0 && total > pageSize" >
            <el-pagination 
                background 
                @current-change="handleCurrentChange"
                layout="total, prev, pager, next"
                :page-size="pageSize"
                :total="total">
            </el-pagination>
        </div>
        <el-dialog :title="$t('project.task_page_dialog_title_1')" :visible.sync="showAcceptDialog" destroy-on-close width="612px" top="20px" @closed="selectedItem = []; $refs.taskTableList.resetTableSelected()">
            <div class="fonts-15 text-center line-height-1.5">{{$t('project.task_page_dialog_message_1')}}</div>
            <template v-for="(task, index) in selectedItem">
                <div class="text-center text-weight-600 line-height-1.5" :key="index">{{task.taskName}} {{task.taskTypeDesc}}</div>
            </template>
            <div class="text-center" slot="footer">
                <el-button plain round size="small" @click="showAcceptDialog = false">{{$t('project.task_page_dialog_btn_label_1')}}</el-button>
                <el-button type="primary" round size="small" :loading="acceptDialogLoading" @click="submitAcceptEvent">{{$t('project.task_page_dialog_btn_label_2')}}</el-button>
            </div>
        </el-dialog>
        <el-dialog :title="$t('project.task_page_dialog_title_2')" :visible.sync="showRefuseDialog" destroy-on-close width="612px" top="20px" @closed="onRefuseDialogClosed">
            <div class="fonts-15 line-height-1.5" v-if="selectedItem">{{$t('project.task_page_dialog_message_2')}}</div>
            <template v-for="(task, index) in selectedItem">
                <div class="text-weight-600 line-height-1.5" :key="index">{{task.taskName}} {{task.taskTypeDesc}}</div>
            </template>
            <div class="margin-t-15">
                <el-input v-model="refuseReason" type="textarea" rows="3" :placeholder="$t('project.task_page_dialog_input_placeholder_1')"></el-input>
            </div>
            <div class="text-center" slot="footer">
                <el-button plain round size="small" @click="showRefuseDialog = false">{{$t('project.task_page_dialog_btn_label_1')}}</el-button>
                <el-button type="primary" round size="small" :loading="refuseDialogLoading" @click="submitRefuseEvent">{{$t('project.task_page_dialog_btn_label_2')}}</el-button>
            </div>
        </el-dialog>
        <el-dialog :visible.sync="showImportDialog" :title="$t('project.task_page_dialog_title_3')" destroy-on-close width="612px" top="20px">
            <div class="fonts-15 line-height-1.5" v-if="selectedItem">{{$t('project.task_page_dialog_message_3')}}</div>
            <template v-for="(task, index) in selectedItem">
                <div class="text-weight-600 line-height-1.5" :key="index">{{task.taskName}} {{task.taskTypeDesc}} {{task.splitRange.startSentenceIndex}}-{{task.splitRange.endSentenceIndex}} {{`句段`}}</div>
            </template>
            <div class="margin-t-15">
                <!-- :size="1000" -->
                <upload-oss ref="importUploadFileDom"
                    :multiple="false"
                    :params="{}"
                    accept=".zip,.rar"
                ></upload-oss>
            </div>
            <div class="text-center" slot="footer">
                <el-button plain round size="small" @click="showImportDialog = false">{{$t('project.task_page_dialog_btn_label_1')}}</el-button>
                <el-button type="primary" round size="small" :loading="importDialogLoading" @click="submitImportDialogEvent">{{$t('project.task_page_dialog_btn_label_2')}}</el-button>
            </div>
        </el-dialog>
        <el-dialog :title="$t('project.task_page_dialog_title_4')" :visible.sync="offerDialog.show" destroy-on-close width="1280px" top="20px">
            <div class="line-height-1.5">
                <div>{{$t('project.task_page_dialog_4_label_1')}}{{offerDialog.task.totalPrice}}</div>
                <div>{{$t('project.task_page_dialog_4_label_2')}}{{offerDialog.task.sourceWords}}</div>
                <div>{{$t('project.task_page_dialog_4_label_3')}}{{offerDialog.task.priceWordCount}}</div>
                <div>{{$t('project.task_page_dialog_4_label_4')}}{{offerDialog.task.initPrice}}</div>
                <div>{{$t('project.task_page_dialog_4_label_9')}}{{offerDialog.task.currencyDesc}}</div>
                <div>{{$t('project.task_page_dialog_4_label_5')}}{{offerDialog.task.additionalRates}}</div>
                <div>{{$t('project.task_page_dialog_4_label_6')}}{{offerDialog.task.priceUnitDesc}}</div>
                <div>{{$t('project.task_page_dialog_4_label_7')}}{{offerDialog.task.projectName}}</div>
            </div>
            <el-divider></el-divider>
            <div class="margin-b-10">{{$t('project.task_page_dialog_4_label_8')}}</div>
            <el-table :data="[offerDialog.task.quotationDetail]" style="width: 100%;" :header-cell-style="{'background-color': '#f5f7fa'}">
              <el-table-column :label="$t('project.task_page_dialog_4_table_label_1')" prop="untranslated"></el-table-column>
              <el-table-column :label="$t('project.task_page_dialog_4_table_label_2')" prop="outerRepeat"></el-table-column>
              <el-table-column :label="$t('project.task_page_dialog_4_table_label_3')" prop="innerRepeat"></el-table-column>
              <el-table-column :label="$t('project.task_page_dialog_4_table_label_4')" prop="match102"></el-table-column>
              <el-table-column :label="$t('project.task_page_dialog_4_table_label_5')" prop="match101"></el-table-column>
              <el-table-column :label="$t('project.task_page_dialog_4_table_label_6')" prop="match100"></el-table-column>
              <el-table-column :label="$t('project.task_page_dialog_4_table_label_7')" prop="match9599"></el-table-column>
              <el-table-column :label="$t('project.task_page_dialog_4_table_label_8')" prop="match8594"></el-table-column>
              <el-table-column :label="$t('project.task_page_dialog_4_table_label_9')" prop="match7584"></el-table-column>
              <el-table-column :label="$t('project.task_page_dialog_4_table_label_10')" prop="match5074"></el-table-column>
              <el-table-column :label="$t('project.task_page_dialog_4_table_label_11')" prop="newWord"></el-table-column>
              <el-table-column :label="$t('project.task_page_dialog_4_table_label_12')" prop="priceWordCount"></el-table-column>
              <el-table-column :label="$t('project.task_page_dialog_4_table_label_13')" prop="workPrice"></el-table-column>

            </el-table>
            <div class="text-center" slot="footer">
                <el-button round plain size="small" @click="offerDialog.show = false">{{$t('project.task_page_dialog_4_table_btn_1')}}</el-button>
                <el-button type="primary" round size="small" :loading="offerDialog.loading" @click="downloadOfferEvent" v-if="offerDialog.task.quotationDetail">{{$t('project.task_page_dialog_4_table_btn_2')}}</el-button>
            </div>
        </el-dialog>
        <el-dialog :title="$t('project.task_page_dialog_title_5')" :visible.sync="showCommitDialog" destroy-on-close width="420px" top="20px" @closed="selectedItem = []; $refs.taskTableList.resetTableSelected()">
            <div class="fonts-15 text-center line-height-1.5">{{$t('project.task_page_dialog_5_tips_1')}}</div>
            <div class="text-center" slot="footer">
                <el-button plain round size="small" @click="showCommitDialog = false">{{$t('project.task_page_dialog_5_btn_1')}}</el-button>
                <el-button type="primary" round size="small" :loading="batchSubmitBtnLoading" @click="batchSubmitBtnEvent">{{$t('project.task_page_dialog_5_btn_2')}}</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import {  mapState } from 'vuex';
import { debounce } from 'lodash';
import { projectApi, commonApi,  } from '@/utils/api';
import FullLoading from '@/components/FullLoading';
import TaskTableList from './components/TaskTableList';
import UploadOss from '@/components/UploadOss';
import config from '@/utils/config';
export default {
    components: {
        FullLoading, TaskTableList, UploadOss
    },
    data () {
        return {
            config,
            view: 'card', // list
            refreshLoading: false,
            searchForm: {
                kw: '',
                source: '',
                target: '',
                owner: '',
                taskType: '',
                order: '',
            },
            list: [],
            total: 0,
            pageNumber: 1,
            pageSize: 16,
            activeFilter: 'ASSIGNED',
            searchTimer: null,
            ownerOptions: [],
            taskTypeOptions: [],
            orderOptions: [],
            showAcceptDialog: false,
            acceptDialogLoading: false,

            showRefuseDialog: false,
            refuseDialogLoading: false,
            refuseReason: '',

            selectedItem: [],

            showImportDialog: false,
            importDialogLoading: false,

            offerDialog: {
                show: false,
                loading: false,
                task: {},
            },
            batchSubmitBtnLoading: false,
            showCommitDialog: false,
            filterCount: 0,
        }
    },
    computed: {
        ...mapState({
            userInfo: state => state.user.userData,
            LANGUAGE_DICT: state => state.common.LANGUAGE_DICT,
        }),
    },
    methods: {
        initPage(showFullLoading){
            if(showFullLoading){
                this.$refs.fullLoading.setShow();
            }
            let url = `${projectApi.queryMyTaskList}?pageNo=${this.pageNumber}&pageSize=${this.pageSize}`;
            if(this.searchForm.kw) {
                url = `${url}&taskNameKeyword=${encodeURI(this.searchForm.kw)}`;
            }
            if(this.searchForm.source) {
                url = `${url}&source=${this.searchForm.source}`;
            }
            if(this.searchForm.target) {
                url = `${url}&target=${this.searchForm.target}`;
            }
            if(this.searchForm.taskType){
                url = `${url}&taskType=${this.searchForm.taskType}`;
            }
            if(this.activeFilter !== 'ALL'){
                url = `${url}&taskStatus=${this.activeFilter}`;
            }
            if(this.searchForm.order){
                url = `${url}&order=${this.searchForm.order}`;
            }
            this.refreshLoading = true;
            this.$ajax.get(url).then(res => {
                if(res.status === 200){
                    this.list = res.data.records;
                    this.total = res.data.total;
                }
            }).finally(() => {
                this.refreshLoading = false;
                this.$refs.fullLoading.setHide();
            })
        },
        initOwnerOptions(){
            let url = `${projectApi.queryAssignedList}?teamId=${this.userInfo.initTeam.teamId}&keyword=`;
            this.$ajax.get(url).then(res => {
                if(res.status === 200) {
                    this.ownerOptions = res.data;
                }
            })
        },
        initTaskTypeOptions () {
            let url = projectApi.queryTaskTypeList;
            this.$ajax.get(url).then(res => {
                if(res.status === 200) {
                    this.taskTypeOptions = res.data.taskType;
                }
            })
        },
        handleCurrentChange(val) {
            this.pageNumber = val;
            this.$nextTick(()=> {
                this.initPage(false);
            })
        },
        inputSearchEvent(val){
            let self = this;
            if(this.searchTimer){
                clearTimeout(this.searchTimer);
                this.searchTimer = null;
            }
            this.searchTimer = setTimeout(() => {
                self.initPage(false);
            }, 500)
        },
        selectSearchEvent(){
            this.$nextTick(()=> {
                this.initPage(false);
            })
        },
        filterClickEvent(tab, event){
            this.$nextTick(()=> {
                this.pageNumber = 1;
                this.initPage(false);
            })
        },
        toWorkbench(item){
            if(['ASSIGNED', 'REJECTED' , 'INVALID'].indexOf(item.status) > -1){
                return;
            }
            this.$router.push({path:'/workbench/editer', query: {id: item.documentId, taskId: item.taskId}});
        },
        batchRefuseBtnEvent() {
            if(this.selectedItem.length === 0) {
                this.$message.error(this.$t('project.task_page_validation_message_1'));
                return;
            }
            this.showRefuseDialog = true;
        },
        showbatchSubmitDialogEvent() {
            if(this.selectedItem.length === 0) {
                this.$message.error(this.$t('project.task_page_validation_message_1'));
                return;
            }
            this.showCommitDialog = true;
        },
        batchSubmitBtnEvent() {
            /* if(this.selectedItem.length === 0) {
                this.$message.error(this.$t('project.task_page_validation_message_1'));
                return;
            } */
            let ids = this.selectedItem.map(item => item.taskId);
            this.batchSubmitBtnLoading = true;
            this.$ajax.post(projectApi.bathSubmitTask, {assignmentIds: ids}).then(res => {
                if(res.status === 200) {
                    this.showCommitDialog = false;
                    this.$message.success(this.$t('project.task_page_validation_message_7'));
                    this.initPage();
                }
            }).finally(() => {
                this.batchSubmitBtnLoading = false;
            })
        },
        refuseBtnEvent(item) {
            this.selectedItem = item;
            this.showRefuseDialog = true;
        },
        submitRefuseEvent() {
            let ids = [];
            this.selectedItem.map(item => {
                ids.push(item.taskId);
            });
            let url = projectApi.acceptOrRefuseTask;
            let postData = {
                taskIdList: ids,
                reason: this.refuseReason,
                accepted: false,
            };
            this.refuseDialogLoading = true;
            this.$ajax.post(url, postData).then(res => {
                if(res.status === 200){
                    this.$message.success(this.$t('project.task_page_validation_message_2'));
                    this.showRefuseDialog = false;
                    this.initPage(false);
                }
            }).finally(() => {
                this.refuseDialogLoading = false;
            });
        },
        onRefuseDialogClosed() {
            this.selectedItem = [];
            this.refuseReason = '';
            this.$refs.taskTableList.resetTableSelected();
        },
        batchAcceptBtnEvent() {
            if(this.selectedItem.length === 0) {
                this.$message.error(this.$t('project.task_page_validation_message_3'));
                return;
            }
            this.showAcceptDialog = true;
        },
        acceptBtnEvent(item) {
            this.selectedItem = item;
            this.showAcceptDialog = true;
        },
        submitAcceptEvent() {
            let ids = [];
            this.selectedItem.map(item => {
                ids.push(item.taskId);
            });
            let url = projectApi.acceptOrRefuseTask;
            let postData = {
                taskIdList: ids,
                accepted: true,
            };
            this.acceptDialogLoading = true;
            this.$ajax.post(url, postData).then(res => {
                if(res.status === 200){
                    this.$message.success(this.$t('project.task_page_validation_message_4'));
                    this.showAcceptDialog = false;
                    this.initPage(false);
                }
            }).finally(() => {
                this.acceptDialogLoading = false;
            });
        },
        changeViewEvent() {
            if(this.view === 'card') {
                this.view = 'list';
            }else if(this.view === 'list') {
                this.view = 'card';
            }
            this.$ls.set('TaskView', this.view);
        },
        exportTaskDoc: debounce(function() {
            //TODO 批量导出
            // this.exportBtnLoading = true;
            let ids = this.selectedItem.map(item => item.assignmentId);
            console.log(this.selectedItem);
            let url = commonApi.batchCreateDownloadTask;
            let postData = {
                title: this.selectedItem[0].taskName,
                metadataIdList: ids,
                metadataType: "TMS_TASK_OFFLINE",
                moreOptions: {},
            };
            this.$ajax.post(url, postData).then(res=>{
                if(res.status === 200){
                    this.$store.dispatch('download/createNotices', res.data.map(item => { return { ...item, show: true}}));
                }
            }).finally(()=>{
                // this.exportBtnLoading = false;
            });
        }, 1000),
        submitImportDialogEvent() {
            let files = this.$refs.importUploadFileDom.getOssFiles();
            // console.log(files);
            if(files.length === 0) {
                this.$message.error(this.$t('project.task_page_validation_message_5'));
                return;
            }
            this.importDialogLoading = true;
            let url = projectApi.importTaskAssignmentFile;
            this.$ajax.post(url, {assignmentId: this.selectedItem[0].assignmentId, filePath: files}).then(res => {
                if(res.status === 200){
                    this.$message.success(this.$t('project.task_page_validation_message_6'));
                    this.showImportDialog = false;
                    this.initPage(false);
                }
            }).finally(() => {
                this.importDialogLoading = false;
                
            })

        },
        showOfferDialog(row) {
            console.log(row);
            this.offerDialog.task = row;
            this.offerDialog.show = true;
        },
        downloadOfferEvent() {
            this.offerDialog.loading = true;
            let url = commonApi.batchCreateDownloadTask;
            let postData = {
                title: this.$t('project.task_page_downloadOfferEvent_title', {name: this.offerDialog.task.taskName}),
                metadataIdList: [this.offerDialog.task.projectId],
                metadataType: "TMS_TASK_QUOTATION",
                 moreOptions: {
                    /*projectId: this.project.projectId,
                    orgId: this.activeOrg.organizationId,*/
                    id: this.offerDialog.task.taskId,
                    type: 'TASK',
                    queryType: 'TRANSLATOR',
                }, 
            };
            this.$ajax.post(url, postData).then(res=>{
                if(res.status === 200){
                    this.$store.dispatch('download/createNotices', res.data.map(item => { return { ...item, show: true}}));
                }
            }).finally(()=>{
                this.offerDialog.loading = false;
            });
        },
        executeAction(key, params) {
            switch (key) {
                case 'updateSelected':
                    this.selectedItem = params;
                    break;
                case 'refuse':
                    this.selectedItem = params;
                    this.showRefuseDialog = true;
                    break;
                case 'accept':
                    this.selectedItem = params;
                    this.showAcceptDialog = true;
                    break;
                case 'export':
                    this.selectedItem = params;
                    this.exportTaskDoc();
                    break;
                case 'import':
                    this.selectedItem = params;
                    this.showImportDialog = true;
                    break;
                case 'showOffer':
                    this.showOfferDialog(params[0]);
                    break;
            }
        },
    },
    created() {
        this.orderOptions = [
            {value: 'FILENAME_ASC', text: this.$t('project.task_page_orderOptions_item_1')},
            {value: 'FILENAME_DESC', text: this.$t('project.task_page_orderOptions_item_2')},
            {value: 'START_TIME_ASC', text: this.$t('project.task_page_orderOptions_item_3')},
            {value: 'START_TIME_DESC', text: this.$t('project.task_page_orderOptions_item_4')},
            {value: 'DEADLINE_TIME_ASC', text: this.$t('project.task_page_orderOptions_item_5')},
            {value: 'DEADLINE_TIME_DESC', text: this.$t('project.task_page_orderOptions_item_6')},
        ];
        let _view = this.$ls.get('TaskView');
        if(_view){
            this.view = _view;
        }else {
            if(this.userInfo.internal) {
                this.view = 'list';
            }
        }
        let _filter = this.$ls.get('taskActiveFilter');
        if(_filter) {
            this.activeFilter = _filter;
        }
        let _form = this.$ls.get('taskSearchFilterParams');
        if(_form) {
            this.searchForm = _form;
        }
    },
    async mounted() {
        this.initPage(true);
        this.initTaskTypeOptions();
        
    },
    watch: {
        'searchForm': {
            deep: true,
            handler(val) {
                this.$ls.set('taskSearchFilterParams', val);
                let count = 0;
                // !(searchForm.taskType || searchForm.order || searchForm.source || searchForm.target)
                if(val.taskType) count ++;
                if(val.order) count ++;
                if(val.source) count ++;
                if(val.target) count ++;
                this.filterCount = count;
            }
        },
        'activeFilter' (val) {
            this.$ls.set('taskActiveFilter', val);
        }
    }

}
</script>
<style lang="scss" scoped>
.project-tasks-page{
    .task-status-tabs {
        ::v-deep .el-tabs__nav-wrap {
            &::after {
                display: none;
            }
        }
        ::v-deep .el-tabs__item {
            height: 57.1px;
            line-height: 57.1px;
        }
    }
    .task-card-list{
        margin: 30px 90px;
        display: grid;
        grid-gap: 30px;
        grid-template-columns: repeat(auto-fill,minmax(190px,1fr));
        .item {
            // min-height: 228px;
            background: #FFFFFF;
            box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05);
            border-radius: 10px;
            padding: 30px 15px 20px 15px;
            cursor: pointer;
            position: relative;
            .more-actions {
                position: absolute;
                top: 10px;
                right: 20px;
            }
            .task-type-label {
                position: absolute;
                left: 0;
                top: 0;
                height: 24px;
                display: flex;
                padding: 0 10px;
                align-items: center;
                font-size: 12px;
                color: #1AC49C;
                background-color: #DDF3EB;
                border-radius: 10px 0 10px 0;
                &.rejected {
                    color: #F56C6C;
                    background-color: #FDE2E2;
                }
            }
            .icon-content{
                width: 50px;
                height: 50px;
                border-radius: 50px;
                background-color: #F8F8F8;
                font-size: 27px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .go-btn{
                width: 50px;
                height: 24px;
                border-radius: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 12px;
                background-color: #f5f5f5;
            }
            .TO_START {
                color: #FE8E0B;
            }
            .IN_PROGRESS {
                color: #2979FF;
            }
            .COMPLETED,
            .FINISHED {
                color: #00B670;
            }
            .ASSIGNED {
                color: #FF9900;
            }
            .REJECTED {
                color: #999999;
            }
            .progress-text {
                position: absolute;
                top: 0;
                right: 0;
                width: 100%;
                height: 100%;
                font-size: 12px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            ::v-deep .el-progress-bar__outer{
                background-color: #F8F8F8;
            }
        }
    }
}
.more-filter-content {
    display: flex;
    flex-direction: column;
    ::v-deep .el-select {
        .el-input__inner {
            // border-radius: 20px;
        }
    }
}
.more-filter-badge {
    ::v-deep .el-badge__content {
        top: 5px;
        right: 10px;
    }
}
</style>
